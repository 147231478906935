import React from "react";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import "./MemberInitial.css";
import MemberTab from "../MemberTab/MemberTab";

const DashboardInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <MemberTab />
    </Box>
  );
};

export default DashboardInitial;
