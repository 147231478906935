import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AppliedLoans from "../AppliedLoans/AppliedLoans";
import TakenLoans from "../TakenLoans/TakenLoans";
import CreateLoanTab from "../CreateLoans/CreateLoanTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);
  const [totalLoaned, setTotalLoaned] = React.useState(0);
  const [totalRequested, setTotalRequested] = React.useState(0);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const takenLoansResponse = await fetch(
          " https://kiamabackend.mathenoid.com/api/sum-taken-loans"
        );
        const takenLoansData = await takenLoansResponse.json();
        setTotalLoaned(takenLoansData.totalAmount);

        const appliedLoansResponse = await fetch(
          " https://kiamabackend.mathenoid.com/api/sum-applied-loans"
        );
        const appliedLoansData = await appliedLoansResponse.json();
        setTotalRequested(appliedLoansData.totalAmount);
      } catch (error) {
        console.error("Error fetching loan data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: 100,
      }}
    >
      <div className="mainMembersDiv">
        <div className="titlesDiv">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider", position: "fixed" }}
            TabIndicatorProps={{
              style: { backgroundColor: "#69c37b" }, // Change the color of the indicator
            }}
          >
            <Tab
              label="Taken Loans"
              {...a11yProps(0)}
              sx={{
                "&.Mui-selected": {
                  color: "#69c37b",
                },
              }}
            />
            <Tab
              label="Applied Loans"
              {...a11yProps(1)}
              sx={{
                "&.Mui-selected": {
                  color: "#69c37b",
                },
              }}
            />
            <Tab
              label=" Create Loans"
              {...a11yProps(2)}
              sx={{
                "&.Mui-selected": {
                  color: "#69c37b",
                },
              }}
            />
            <Tab
              label="Total Loaned"
              {...a11yProps(3)}
              sx={{
                "&.Mui-selected": {
                  color: "#69c37b",
                },
              }}
            />
            <Tab
              label="Loans Request Total"
              {...a11yProps(4)}
              sx={{
                "&.Mui-selected": {
                  color: "#69c37b",
                },
              }}
            />
          </Tabs>
        </div>
      </div>

      <div className="tabContent">
        <TabPanel value={value} index={0}>
          <TakenLoans />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AppliedLoans />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CreateLoanTab />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="fs-2 text-success">
            KSh {totalLoaned.toLocaleString()}
          </div>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div className="fs-2 text-success">
            KSh {totalRequested.toLocaleString()}
          </div>
        </TabPanel>
      </div>
    </Box>
  );
}
