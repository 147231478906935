import * as React from "react";

import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";

import DashboardIcon from "@mui/icons-material/Dashboard";
import Loans from "@mui/icons-material/Money";
import Guarantee from "@mui/icons-material/People";
import Payment from "@mui/icons-material/Payment";
import Share from "@mui/icons-material/EnergySavingsLeafSharp";
import Report from "@mui/icons-material/FileCopy";
import VA from "@mui/icons-material/AssistWalker";
import Finances from "@mui/icons-material/AccountBalance";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import GavelIcon from "@mui/icons-material/Gavel";

import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Pic from "../../Assets/Images/person.png";
import Logout from "../LogoutContextMenu/LogoutContextMenu";
import "./SideBarEvents.css";

import { Link } from "react-router-dom";
import EventsInitial from "../EventsInitial/EventsInitial";
const drawerWidth = 190;

export default function SideBarDashboard() {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="leftmenu">
            <img src={Pic} alt="" width={50} />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                color: "rgb(105, 195, 123)",
                fontFamily: "poppins",
              }}
            >
              NOID MICROFINANCE
            </Typography>
          </div>

          <div className="rightmenu">
            <Logout />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            color: "white",
          },
        }}
      >
        <Toolbar />
        <Box
          sx={{
            overflow: "auto",
            backgroundColor: "#69c37b",
            height: "100dvh",
          }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<DashboardIcon />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Dashboard">
                <ListItemText primary="Dashboard" />
              </Link>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<Loans />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Loans">
                <ListItemText primary="Loans" />
              </Link>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<GavelIcon />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Fines">
                <ListItemText primary="Fines" />
              </Link>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<Payment />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Payments">
                <ListItemText primary="Payments" />
              </Link>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<Finances />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Finances">
                <ListItemText primary="Finances" />
              </Link>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<Share />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Shares">
                <ListItemText primary="Shares" />
              </Link>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<AttachFileIcon />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Repository">
                <ListItemText primary="Repository" />
              </Link>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<Guarantee />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Member">
                <ListItemText primary="Members" />
              </Link>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<Report />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Reports">
                <ListItemText primary="Reports" />
              </Link>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<EventAvailableIcon />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Events">
                <ListItemText primary="Events" />
              </Link>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<VA />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/VirtualAssistant">
                <ListItemText primary="Virtual Assistant" />
              </Link>
            </ListItemButton>
          </ListItem>

          <Divider />
          <br />
          <div
            style={{
              display: "flex",
              width: drawerWidth * 0.95,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              href="https://mathenoid.com"
              target="_blank"
              rel="noreferrer"
              className="owner"
            >
              mathenoidlabs &copy; 2024
            </a>
          </div>
        </Box>
      </Drawer>
      <EventsInitial />
    </Box>
  );
}
