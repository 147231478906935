import React from "react";

const SharesDistribution = () => {
  const createDummyData = () => {
    const totalShares = 2500;
    const rows = [];
    let remainingShares = totalShares;
    const rowCount = 50;

    for (let i = 1; i <= rowCount; i++) {
      let shares;
      if (i === rowCount) {
        shares = remainingShares; // Assign remaining shares to the last row
      } else {
        // Calculate average remaining shares per row
        const averageRemaining = Math.ceil(
          remainingShares / (rowCount - i + 1)
        );
        shares = Math.floor(Math.random() * averageRemaining) + 1;
        remainingShares -= shares;
      }
      const value = shares * 334;
      rows.push({
        serial: i,
        registrationNumber: "NOID096VX",
        shares,
        value,
      });
    }
    return rows;
  };

  const data = createDummyData();

  return (
    <div className="container mt-5">
      <h2>Shares Distribution</h2>
      <div
        className="table-responsive"
        style={{ maxHeight: "400px", overflowY: "auto" }}
      >
        <table className="table table-striped">
          <thead className="thead-dark" style={{ position: "sticky", top: 0 }}>
            <tr>
              <th scope="col">Serial</th>
              <th scope="col">Registration Number</th>
              <th scope="col">Shares</th>
              <th scope="col">Value (KSh)</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={row.serial}>
                <td>{row.serial}</td>
                <td>{row.registrationNumber}</td>
                <td>{row.shares}</td>
                <td>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SharesDistribution;
