import React from "react";
import SideBarFines from "../../Components/SideBarFines/SideBarFines";

const Fines = () => {
  return (
    <div>
      <SideBarFines />
    </div>
  );
};

export default Fines;
