import React from "react";
import SharesSummary from "../SharesSummary/SharesSummary";

const TotalShares = () => {
  return (
    <div>
      <SharesSummary />
    </div>
  );
};

export default TotalShares;
