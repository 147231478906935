import React, { useState, useEffect } from "react";
import { Table, Button, Alert, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

// Snackbar component
const Snackbar = ({ message, onClose, duration }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, duration);
    return () => clearTimeout(timer);
  }, [onClose, duration]);

  return (
    <Alert variant="success" onClose={onClose} dismissible>
      {message}
    </Alert>
  );
};

const FinesTable = () => {
  const [fines, setFines] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [newFine, setNewFine] = useState({
    date: "",
    person: "",
    reason: "",
    amount: "",
    fined: false,
  });

  useEffect(() => {
    fetchFines();
  }, []);

  const fetchFines = async () => {
    try {
      const response = await axios.get(
        " https://kiamabackend.mathenoid.com/api/fines"
      );
      setFines(response.data.fines);
    } catch (error) {
      console.error("Error fetching fines:", error);
    }
  };

  const handleNotify = async (fine) => {
    try {
      const response = await axios.get(
        ` https://kiamabackend.mathenoid.com/api/member-phone/${fine.person}`
      );
      const phoneNumber = response.data.phone_number;

      const message = `Hello ${fine.person}, you have been fined Ksh ${fine.amount} for ${fine.reason}.`;

      await axios.post(" https://kiamabackend.mathenoid.com/sendsms", {
        message,
        mobile: phoneNumber,
      });

      setSnackbarMessage(`Member notified about ${fine.reason} fine.`);
      setShowSnackbar(true);
      fine.fined = true; // Update fined status to true
      setFines([...fines]);
    } catch (error) {
      console.error("Error notifying member:", error);
    }
  };

  const handleNotifyAll = async () => {
    try {
      for (const fine of fines) {
        if (!fine.fined) {
          const response = await axios.get(
            ` https://kiamabackend.mathenoid.com/api/member-phone/${fine.person}`
          );
          const phoneNumber = response.data.phone_number;

          const message = `Hello ${fine.person}, you have been fined Ksh ${fine.amount} for ${fine.reason}.`;

          const smsData = {
            message: message,
            mobile: phoneNumber,
          };

          await axios.post(" https://kiamabackend.mathenoid.com/sendsms", {});

          fine.fined = true; // Update fined status to true
        }
      }

      setSnackbarMessage("All members notified.");
      setShowSnackbar(true);
      setFines([...fines]);
    } catch (error) {
      console.error("Error notifying all members:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewFine({ ...newFine, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        " https://kiamabackend.mathenoid.com/api/fines",
        newFine
      );
      setSnackbarMessage("New fine added.");
      setShowSnackbar(true);
      fetchFines();
      handleCloseModal();
    } catch (error) {
      console.error("Error adding fine:", error);
    }
  };

  // Sort fines by date in descending order
  const sortedFines = [...fines].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  // Group the sorted fines by date
  const groupedFines = sortedFines.reduce((acc, fine) => {
    (acc[formatDate(fine.date)] = acc[formatDate(fine.date)] || []).push(fine);
    return acc;
  }, {});

  return (
    <div className="container mt-4">
      <h3 className="my-5">Fines</h3>
      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          onClose={closeSnackbar}
          duration={5000}
        />
      )}

      {Object.keys(groupedFines).map((date) => (
        <div key={date}>
          <h4>{date}</h4>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Fined</th>
                <th>Reason</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {groupedFines[date].map((fine, index) => (
                <tr key={index}>
                  <td>{fine.person}</td>
                  <td>{fine.reason}</td>
                  <td>Ksh {fine.amount}</td>
                  <td>
                    <Button variant="success" className="mx-3">
                      Payed
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => handleNotify(fine)}
                    >
                      Notify
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ))}

      <div className="d-flex justify-content-end mt-3">
        <Button variant="primary" className="me-2" onClick={handleShowModal}>
          Add Fine
        </Button>

        <Button variant="success" onClick={handleNotifyAll}>
          Notify All
        </Button>
      </div>

      <Modal className="mt-5" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Fine</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formDate">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={newFine.date}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPerson" className="mt-3">
              <Form.Label>Fined</Form.Label>
              <Form.Control
                type="text"
                name="person"
                value={newFine.person}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formReason" className="mt-3">
              <Form.Label>Reason</Form.Label>
              <Form.Control
                type="text"
                name="reason"
                value={newFine.reason}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formAmount" className="mt-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={newFine.amount}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-4">
              Add Fine
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FinesTable;
