import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const SharesFlow = () => {
  const createDummyData = () => {
    const totalShares = 2500;
    const rowCount = 20;
    const rows = [];
    const dates = [
      "01/01/2023",
      "01/02/2023",
      "01/03/2023",
      "01/04/2023",
      "01/05/2023",
      "01/06/2023",
      "01/07/2023",
      "01/08/2023",
      "01/09/2023",
      "01/10/2023",
      "01/11/2023",
      "01/12/2023",
      "01/13/2023",
      "01/14/2023",
      "01/15/2023",
      "01/16/2023",
      "01/17/2023",
      "01/18/2023",
      "01/19/2023",
      "01/20/2023",
    ];

    let remainingShares = totalShares;

    for (let i = 1; i <= rowCount; i++) {
      let shares;
      if (i === rowCount) {
        shares = remainingShares; // Assign remaining shares to the last row
      } else {
        const averageRemaining = Math.ceil(
          remainingShares / (rowCount - i + 1)
        );
        shares = Math.floor(Math.random() * averageRemaining) + 1;
        remainingShares -= shares;
      }
      const value = shares * 334;
      rows.push({
        serial: i,
        from: "NOID097VX",
        to: "NOID564MC",
        date: dates[i - 1],
        shares,
        value,
      });
    }
    return rows;
  };

  const data = createDummyData();

  return (
    <div className="container mt-5">
      <h2>Shares Flow</h2>
      <div
        className="table-responsive"
        style={{ maxHeight: "400px", overflowY: "auto" }}
      >
        <table className="table table-striped">
          <thead className="thead-dark" style={{ position: "sticky", top: 0 }}>
            <tr>
              <th scope="col">Serial</th>
              <th scope="col">From</th>
              <th scope="col">To</th>
              <th scope="col">Date</th>
              <th scope="col">Shares</th>
              <th scope="col">Value (KSh)</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <tr key={row.serial}>
                <td>{row.serial}</td>
                <td>{row.from}</td>
                <td>{row.to}</td>
                <td>{row.date}</td>
                <td>{row.shares}</td>
                <td>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SharesFlow;
