import React from "react";

const SharesSummary = () => {
  const totalShares = 2500;
  const totalValue = totalShares * 334;

  const summaryStyle = {
    color: "#69c37b",
    fontSize: "2rem",
    margin: "20px 0",
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={summaryStyle}>
        TOTAL SHARES: {totalShares.toLocaleString()}
      </div>
      <div style={summaryStyle}>
        TOTAL AMOUNT: KSh {totalValue.toLocaleString()}
      </div>
    </div>
  );
};

export default SharesSummary;
