import React from "react";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import PaymentsTable from "../PaymentsTable/PaymentsTable";
import "./PaymentsInitial.css";

const PaymentsInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <PaymentsTable />
    </Box>
  );
};

export default PaymentsInitial;
