import React, { useEffect, useState } from "react";
import "./InactiveMembers.css";

const InactiveMembers = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetch(" https://kiamabackend.mathenoid.com/getInactiveMembers")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setRows(data.members);
        }
      })
      .catch((error) =>
        console.error("Error fetching inactive members:", error)
      );
  }, []);

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th className="greenHeaders" scope="col">
              Serial
            </th>
            <th className="greenHeaders" scope="col">
              Name
            </th>
            <th className="greenHeaders" scope="col">
              Registration
            </th>
            <th className="greenHeaders" scope="col">
              Id
            </th>
            <th className="greenHeaders" scope="col">
              Phone
            </th>
            <th className="greenHeaders" scope="col">
              Email
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={row.id_number}>
              <th scope="row">{index + 1}</th>
              <td>{row.name}</td>
              <td>{row.registration_number}</td>
              <td>{row.id_number}</td>
              <td>{row.phone_number}</td>
              <td>{row.email_address}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InactiveMembers;
