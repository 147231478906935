import React from "react";
import SideBarEvents from "../../Components/SideBarEvents/SideBarEvents";

const Events = () => {
  return (
    <div>
      <SideBarEvents />
    </div>
  );
};

export default Events;
