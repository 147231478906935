import React from "react";
import SideBarFinance from "../../Components/SideBarFinance/SideBarFinance";

const Finance = () => {
  return (
    <div>
      <SideBarFinance />
    </div>
  );
};

export default Finance;
