import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Table, Modal } from "react-bootstrap";
import axios from "axios";
import "./ModifyMember.css";

const MembersList = () => {
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    try {
      const response = await axios.get(
        " https://kiamabackend.mathenoid.com/getMembers"
      );
      setMembers(response.data.members);
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  const handleUpdateClick = async (idNumber) => {
    try {
      const response = await axios.get(
        ` https://kiamabackend.mathenoid.com/getMember/${idNumber}`
      );
      setSelectedMember(response.data.member);
      setShowForm(true);
    } catch (error) {
      console.error("Error fetching member:", error);
    }
  };

  const handleUpdateMember = async () => {
    setShowConfirmation(false);
    try {
      await axios.put(
        ` https://kiamabackend.mathenoid.com/modifyMember/${selectedMember.id_number}`,
        selectedMember
      );
      setShowSuccess(true);
      fetchMembers();
      setShowForm(false);
    } catch (error) {
      console.error("Error updating member:", error);
    }
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setSelectedMember(null);
  };

  const handlePersonalDetailsChange = (e) => {
    const { name, value } = e.target;
    setSelectedMember((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setSelectedMember((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleNextOfKinChange = (e) => {
    const { name, value } = e.target;
    setSelectedMember((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <div className="table-container">
      {!showForm && (
        <table className="table">
          <thead>
            <tr>
              <th className="greenHeaders" scope="col">
                Serial
              </th>
              <th className="greenHeaders" scope="col">
                Name
              </th>
              <th className="greenHeaders" scope="col">
                Registration
              </th>
              <th className="greenHeaders" scope="col">
                Id
              </th>
              <th className="greenHeaders" scope="col">
                Phone
              </th>
              <th className="greenHeaders" scope="col">
                Email
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member, index) => (
              <tr key={member.id_number}>
                <th scope="row">{index + 1}</th>
                <td>{member.name}</td>
                <td>{member.registration_number}</td>
                <td>{member.id_number}</td>
                <td>{member.phone_number}</td>
                <td>{member.email_address}</td>
                <td>
                  <Button onClick={() => handleUpdateClick(member.id_number)}>
                    Update
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {showForm && selectedMember && (
        <Form>
          <h2 className="">Personal Details</h2>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formRegistrationNumber">
              <Form.Label>Registration Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter registration number"
                name="registration_number"
                value={selectedMember.registration_number}
                onChange={handlePersonalDetailsChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formIdNumber">
              <Form.Label>ID Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter ID number"
                name="id_number"
                value={selectedMember.id_number}
                onChange={handlePersonalDetailsChange}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                name="name"
                value={selectedMember.name}
                onChange={handlePersonalDetailsChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone number"
                name="phone_number"
                value={selectedMember.phone_number}
                onChange={handlePersonalDetailsChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formAccNumber">
              <Form.Label>Acc Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter account number"
                name="acc_number"
                value={selectedMember.acc_number}
                onChange={handlePersonalDetailsChange}
              />
            </Form.Group>
          </Row>

          <h2 className="mb-4">Address</h2>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formCounty">
              <Form.Label>County</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter county"
                name="county"
                value={selectedMember.county}
                onChange={handleAddressChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formLocation">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter location"
                name="location"
                value={selectedMember.location}
                onChange={handleAddressChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formEmailAddress">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email address"
                name="email_address"
                value={selectedMember.email_address}
                onChange={handleAddressChange}
              />
            </Form.Group>
          </Row>

          <h2 className="mb-4">Next of Kin</h2>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formNextOfKinIdNumber">
              <Form.Label>ID Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter ID number"
                name="next_of_kin_id_number"
                value={selectedMember.next_of_kin_id_number}
                onChange={handleNextOfKinChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formNextOfKinName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                name="next_of_kin_name"
                value={selectedMember.next_of_kin_name}
                onChange={handleNextOfKinChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formRelationship">
              <Form.Label>Relationship</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter relationship"
                name="relationship"
                value={selectedMember.relationship}
                onChange={handleNextOfKinChange}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formNextOfKinPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone number"
                name="next_of_kin_phone_number"
                value={selectedMember.next_of_kin_phone_number}
                onChange={handleNextOfKinChange}
              />
            </Form.Group>
          </Row>

          <Button
            className="btn btn-sm btn-success mx-3"
            onClick={() => setShowConfirmation(true)}
          >
            Update Member
          </Button>
          <Button className="btn btn-sm btn-danger" onClick={handleCloseForm}>
            Cancel
          </Button>
        </Form>
      )}

      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to update this member?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdateMember}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSuccess} onHide={() => setShowSuccess(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Member updated successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSuccess(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MembersList;
