import React, { useState, useEffect } from "react";
import axios from "axios";

const CreateLoanType = () => {
  const [loanTypes, setLoanTypes] = useState([]);
  const [newLoanType, setNewLoanType] = useState({ name: "", description: "" });

  useEffect(() => {
    fetchLoanTypes();
  }, []);

  const fetchLoanTypes = async () => {
    try {
      const response = await axios.get(
        " https://kiamabackend.mathenoid.com/api/loan-types"
      );
      setLoanTypes(response.data.loanTypes);
    } catch (error) {
      console.error("Error fetching loan types:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this loan type?")) {
      try {
        await axios.delete(
          ` https://kiamabackend.mathenoid.com/api/loan-types/${id}`
        );
        fetchLoanTypes();
      } catch (error) {
        console.error("Error deleting loan type:", error);
      }
    }
  };

  const handleAdd = async () => {
    if (newLoanType.name && newLoanType.description) {
      try {
        await axios.post(
          " https://kiamabackend.mathenoid.com/api/loan-types",
          newLoanType
        );
        setNewLoanType({ name: "", description: "" });
        fetchLoanTypes();
      } catch (error) {
        console.error("Error adding loan type:", error);
      }
    }
  };

  return (
    <div className="container mt-5">
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loanTypes.map((loanType, index) => (
            <tr key={loanType.id}>
              <td>{index + 1}</td>
              <td>{loanType.name}</td>
              <td>{loanType.description}</td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(loanType.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td>{loanTypes.length + 1}</td>
            <td>
              <input
                type="text"
                value={newLoanType.name}
                onChange={(e) =>
                  setNewLoanType({ ...newLoanType, name: e.target.value })
                }
                required
                className="form-control"
                placeholder="Name"
              />
            </td>
            <td>
              <input
                type="text"
                value={newLoanType.description}
                onChange={(e) =>
                  setNewLoanType({
                    ...newLoanType,
                    description: e.target.value,
                  })
                }
                required
                className="form-control"
                placeholder="Description"
              />
            </td>
            <td>
              <button className="btn btn-success" onClick={handleAdd}>
                Add
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CreateLoanType;
