import React from "react";
import SideBarMember from "../../Components/SideBarMember/SidebarMember";

const Member = () => {
  return (
    <div>
      <SideBarMember />
    </div>
  );
};

export default Member;
