import React, { useState, useEffect } from "react";
import axios from "axios";

const CreateLoanplan = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchLoanPlans();
  }, []);

  const fetchLoanPlans = async () => {
    try {
      const response = await axios.get(
        " https://kiamabackend.mathenoid.com/api/loan-plans"
      );
      setRows(response.data.loanPlans);
    } catch (error) {
      console.error("Error fetching loan plans:", error);
    }
  };

  const addRow = () => {
    setRows([...rows, { period: "", interest: "", penalty: "" }]);
  };

  const removeRow = async (index) => {
    const loanPlan = rows[index];
    if (window.confirm("Are you sure you want to delete this loan plan?")) {
      try {
        await axios.delete(
          ` https://kiamabackend.mathenoid.com/api/loan-plans/${loanPlan.id}`
        );
        fetchLoanPlans();
      } catch (error) {
        console.error("Error deleting loan plan:", error);
      }
    }
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };

  const handleAdd = async () => {
    const newLoanPlan = rows[rows.length - 1];
    if (window.confirm("Are you sure you want to add this loan plan?")) {
      try {
        await axios.post(
          " https://kiamabackend.mathenoid.com/api/loan-plans",
          newLoanPlan
        );
        fetchLoanPlans();
      } catch (error) {
        console.error("Error adding loan plan:", error);
      }
    }
  };

  return (
    <div className="container">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Period (in years)</th>
            <th scope="col">Interest (in %)</th>
            <th scope="col">Overdue Penalty (in %)</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>
                <input
                  type="number"
                  className="form-control"
                  name="period"
                  value={row.period}
                  onChange={(e) => handleChange(index, e)}
                  required
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  name="interest"
                  value={row.interest}
                  onChange={(e) => handleChange(index, e)}
                  required
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  name="penalty"
                  value={row.penalty}
                  onChange={(e) => handleChange(index, e)}
                  required
                />
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => removeRow(index)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button type="button" className="btn btn-success mx-3" onClick={addRow}>
        Add Row
      </button>
      <button type="button" className="btn btn-primary" onClick={handleAdd}>
        Add Loan Plan
      </button>
    </div>
  );
};

export default CreateLoanplan;
