import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { Typography } from "@mui/material";
import "./FinancesComponents.css";
import GaugeChart from "react-gauge-chart";
import axios from "axios";

const FinancePage = () => {
  const [data, setData] = useState([]);
  const [assets, setAssets] = useState(0);
  const [liabilities, setLiabilities] = useState(0);
  const [availableAmount, setAvailableAmount] = useState(0);
  const [lendingPower, setLendingPower] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [contributionsRes, interestsRes, finesRes, loansRes] =
          await Promise.all([
            axios.get(
              " https://kiamabackend.mathenoid.com/api/sum-payments/AGM contribution"
            ),
            axios.get(
              " https://kiamabackend.mathenoid.com/api/sum-payments/Interests"
            ),
            axios.get(
              " https://kiamabackend.mathenoid.com/api/sum-payments/Fine"
            ),
            axios.get(
              " https://kiamabackend.mathenoid.com/api/sum-payments/Loan"
            ),
          ]);

        console.log("Contributions:", contributionsRes.data);
        console.log("Interests:", interestsRes.data);
        console.log("Fines:", finesRes.data);
        console.log("Loans:", loansRes.data);

        const contributions =
          parseFloat(contributionsRes.data.totalAmount) || 0;
        const interests = parseFloat(interestsRes.data.totalAmount) || 0;
        const finesAmount = parseFloat(finesRes.data.totalAmount) || 0;
        const loansAmount = parseFloat(loansRes.data.totalAmount) || 0;

        setData([
          { name: "Loans", value: loansAmount, color: "#FF6347" },
          { name: "Contributions", value: contributions, color: "#4682B4" },
          { name: "Fines", value: finesAmount, color: "#32CD32" },
          { name: "Interests", value: interests, color: "#FFD700" },
        ]);

        setAssets(contributions + interests + finesAmount);
        setLiabilities(loansAmount);
        setAvailableAmount(
          contributions + interests + finesAmount - loansAmount
        );

        // Calculate lending power
        const calculatedLendingPower =
          ((contributions + interests + finesAmount - loansAmount) /
            loansAmount) *
          100;
        setLendingPower(Math.max(0, Math.min(100, calculatedLendingPower)));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container fluid className="dashboard-container">
      <Row className="mb-4 ">
        <Col>
          <h1 className="dashboard-title">Finance Overview</h1>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Financial Distribution</Card.Title>
              <PieChart width={300} height={300}>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  innerRadius={60}
                  fill="#8884d8"
                  dataKey="value"
                  label
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-4">
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Balance Sheet</Card.Title>
              <Typography variant="subtitle1">Assets:</Typography>
              <Typography>
                Contributions: KSh{" "}
                {data
                  .find((item) => item.name === "Contributions")
                  ?.value.toLocaleString()}
              </Typography>
              <Typography>
                Interests: KSh{" "}
                {data
                  .find((item) => item.name === "Interests")
                  ?.value.toLocaleString()}
              </Typography>
              <Typography>
                Fines: KSh{" "}
                {data
                  .find((item) => item.name === "Fines")
                  ?.value.toLocaleString()}
              </Typography>
              <Typography variant="subtitle1" className="mt-3">
                Liabilities:
              </Typography>
              <Typography>
                Loans: KSh{" "}
                {data
                  .find((item) => item.name === "Loans")
                  ?.value.toLocaleString()}
              </Typography>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Available Amount</Card.Title>
              <Typography variant="h5">
                KSh {availableAmount.toLocaleString()}
              </Typography>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-4">
          <Card className="dashboard-card">
            <Card.Body>
              <Card.Title>Organizational Lending Power</Card.Title>
              <Typography variant="h5">{`${Math.round(
                lendingPower
              )}%`}</Typography>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="gauge-container">
          <Card className="dashboard-card dashboard-carded">
            <Card.Body>
              <Card.Title>Organizational Power Indicator</Card.Title>
              <GaugeChart
                id="gauge-chart"
                nrOfLevels={3}
                colors={["#FF0000", "#0000FF", "#00FF00"]}
                arcWidth={0.3}
                percent={lendingPower / 100}
                needleColor="#69c37b"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FinancePage;
