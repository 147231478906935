import React from "react";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import "./FinesInitial.css";
import FinesTable from "../FinesTable/FinesTable";

const FinesInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <FinesTable />
    </Box>
  );
};

export default FinesInitial;
