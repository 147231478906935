import React from "react";
import Login from "./Pages/Login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Profile from "./Pages/Profile/Profile";
import Member from "./Pages/Member/Member";
import Events from "./Pages/Events/Events";
import Fines from "./Pages/Fines/Fines";
import Payments from "./Pages/Payments/Payments";
import Loans from "./Pages/Loans/Loans";
import Shares from "./Pages/Shares/Shares";
import Repository from "./Pages/Repository/Repository";
import Finance from "./Pages/Finance/Finance";
import Reports from "./Pages/Reports/Reports";
import VirtualAssistant from "./Pages/VirtualAssistant/VirtualAssistant";
import UserContextProvider from "./contexts/UserContextProvider";

const App = () => {
  return (
    <UserContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Member" element={<Member />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/Fines" element={<Fines />} />
          <Route path="/Payments" element={<Payments />} />
          <Route path="/Loans" element={<Loans />} />
          <Route path="/Shares" element={<Shares />} />
          <Route path="/Repository" element={<Repository />} />
          <Route path="/Finances" element={<Finance />} />
          <Route path="/Reports" element={<Reports />} />
          <Route path="/VirtualAssistant" element={<VirtualAssistant />} />
        </Routes>
      </BrowserRouter>
    </UserContextProvider>
  );
};

export default App;
