import React from "react";
import "./Shares.css";
import SideBarShares from "../../Components/SideBarShares/SideBarShares";

const Shares = () => {
  return (
    <div>
      <SideBarShares />
    </div>
  );
};

export default Shares;
