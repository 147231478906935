import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const TakenLoans = () => {
  const [loansData, setLoansData] = useState([]);

  useEffect(() => {
    fetch(" https://kiamabackend.mathenoid.com/api/taken-loans")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setLoansData(data.loans);
        }
      })
      .catch((error) => console.error("Error fetching loans:", error));
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <div className="container">
      <table className="table table-striped">
        <thead className="thead-dark sticky-top">
          <tr>
            <th scope="col">Serial</th>
            <th scope="col">Loanee</th>
            <th scope="col">Loan Type</th>
            <th scope="col">Loan Plan</th>
            <th scope="col">Date</th>
            <th scope="col">Amount</th>
            <th scope="col">Due Date</th>
            <th scope="col">Comply</th>
            <th scope="col">Completed</th>
          </tr>
        </thead>
        <tbody>
          {loansData.map((loan) => (
            <tr key={loan.serial}>
              <td>{loan.serial}</td>
              <td>{loan.loanee}</td>
              <td>{loan.loantype}</td>
              <td>
                {loan.loanplan.split("\n").map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </td>
              <td>{formatDate(loan.date)}</td>
              <td>{loan.amount}</td>
              <td>{formatDate(loan.duedate)}</td>
              <td>{loan.comply ? "True" : "False"}</td>
              <td>{loan.completed ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TakenLoans;
