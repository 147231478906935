import React from "react";
import "./ReportsInitial.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import TakenLoans from "../TakenLoans/TakenLoans";
import SharesDistribution from "../SharesTabs/SharesDistribution";
import PaymentsTable from "../PaymentsTable/PaymentsTable";

const ReportsInitial = () => {
  const handlePrint = () => {
    window.print();
  };
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="reportsInitialMainDiv">
        <div className="printBtn">
          <button className="btn btn-md btn-success" onClick={handlePrint}>
            Print Reports
          </button>
        </div>
        <div className="loansReport">
          <TakenLoans />
        </div>
        <br />
        <hr />
        <div className="sharesReport">
          <SharesDistribution />
        </div>
        <br />
        <hr />
        <div className="paymentsReport">
          <PaymentsTable />
        </div>
        <br />
        <hr />
      </div>
    </Box>
  );
};

export default ReportsInitial;
