import React, { useState } from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";

const MyForm = () => {
  const [formData, setFormData] = useState({
    registration_number: "",
    id_number: "",
    name: "",
    phone_number: "",
    acc_number: "",
    county: "",
    location: "",
    email_address: "",
    next_of_kin_id_number: "",
    next_of_kin_name: "",
    relationship: "",
    next_of_kin_phone_number: "",
  });

  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        " https://kiamabackend.mathenoid.com/addMember",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (data.success) {
        setSuccess(true);
        setMessage(data.message);
      } else {
        setSuccess(false);
        setMessage(data.errors);
      }
    } catch (error) {
      setSuccess(false);
      setMessage(error.message);
    }
  };

  return (
    <Form className="addMemberForm" onSubmit={handleSubmit}>
      <h2 className="mb-4">Personal Details</h2>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formRegistrationNumber">
          <Form.Label>Registration Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter registration number"
            name="registration_number"
            value={formData.registration_number}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formIdNumber">
          <Form.Label>ID Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter ID number"
            name="id_number"
            value={formData.id_number}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formPhoneNumber">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter phone number"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formAccNumber">
          <Form.Label>Acc Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter account number"
            name="acc_number"
            value={formData.acc_number}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>

      <h2 className="mb-4">Address</h2>
      <Row className="mb-3 bg-muted">
        <Form.Group as={Col} controlId="formCounty">
          <Form.Label>County</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter county"
            name="county"
            value={formData.county}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formLocation">
          <Form.Label>Location</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter location"
            name="location"
            value={formData.location}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formEmailAddress">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email address"
            name="email_address"
            value={formData.email_address}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>

      <h2 className="mb-4">Next of Kin</h2>
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formNextOfKinIdNumber">
          <Form.Label>ID Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter ID number"
            name="next_of_kin_id_number"
            value={formData.next_of_kin_id_number}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formNextOfKinName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter name"
            name="next_of_kin_name"
            value={formData.next_of_kin_name}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formRelationship">
          <Form.Label>Relationship</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter relationship"
            name="relationship"
            value={formData.relationship}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formNextOfKinPhoneNumber">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter phone number"
            name="next_of_kin_phone_number"
            value={formData.next_of_kin_phone_number}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>

      <Button className="btn btn-sm btn-success" type="submit">
        Add Member
      </Button>

      {message && (
        <Alert variant={success ? "success" : "danger"} className="mt-3">
          {message}
        </Alert>
      )}
    </Form>
  );
};

export default MyForm;
