import React from "react";
import SideBarPayments from "../../Components/SideBarPayments/SideBarPayments";

const Payments = () => {
  return (
    <div>
      <SideBarPayments />
    </div>
  );
};

export default Payments;
