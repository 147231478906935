import React from "react";
import "./Loans.css";
import SideBarLoans from "../../Components/SideBarLoans/SideBarLoans";

const Loans = () => {
  return (
    <div>
      <SideBarLoans />
    </div>
  );
};

export default Loans;
