import React, { useEffect, useState } from "react";
import "./RemoveMember.css"; // Import the CSS file

const TableWithDelete = () => {
  const [members, setMembers] = useState([]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await fetch(
          " https://kiamabackend.mathenoid.com/getMembers"
        );
        const data = await response.json();
        if (data.success) {
          setMembers(data.members);
        }
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchMembers();
  }, []);

  const handleDelete = async (id_number) => {
    if (window.confirm("Confirm Member Removal")) {
      try {
        const response = await fetch(
          ` https://kiamabackend.mathenoid.com/deleteMember/${id_number}`,
          {
            method: "DELETE",
          }
        );
        const data = await response.json();
        if (data.success) {
          setSuccess(true);
          setMessage(data.message);
          // Refresh the members list
          const updatedMembers = members.filter(
            (member) => member.id_number !== id_number
          );
          setMembers(updatedMembers);
        } else {
          setSuccess(false);
          setMessage(data.errors);
        }
      } catch (error) {
        setSuccess(false);
        setMessage(error.message);
      }
    }
  };

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th className="greenHeaders" scope="col">
              Serial
            </th>
            <th className="greenHeaders" scope="col">
              Name
            </th>
            <th className="greenHeaders" scope="col">
              Registration
            </th>
            <th className="greenHeaders" scope="col">
              Id
            </th>
            <th className="greenHeaders" scope="col">
              Phone
            </th>
            <th className="greenHeaders" scope="col">
              Email
            </th>
            <th className="greenHeaders" scope="col">
              Admin
            </th>
            <th className="greenHeaders" scope="col">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {members.map((member, index) => (
            <tr key={member.id_number}>
              <td>{index + 1}</td>
              <td>{member.name}</td>
              <td>{member.registration_number}</td>
              <td>{member.id_number}</td>
              <td>{member.phone_number}</td>
              <td>{member.email_address}</td>
              <td>
                <select
                  value={member.admin}
                  // onChange={(e) => handleAdminChange(index, e.target.value)}
                >
                  <option value="NOID09YT">NOID09YT</option>
                  <option value="NOID567XY">NOID567XY</option>
                  <option value="NOID54CVY">NOID54CVY</option>
                </select>
              </td>
              <td>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => handleDelete(member.id_number)}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {message && (
        <div
          className={`alert ${success ? "alert-success" : "alert-danger"}`}
          role="alert"
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default TableWithDelete;
