import React from "react";
import SideBarReport from "../../Components/SideBarReport/SideBarReport";

const Reports = () => {
  return (
    <div>
      <SideBarReport />
    </div>
  );
};

export default Reports;
