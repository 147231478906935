import React, { useEffect, useState } from "react";
import axios from "axios";
import "./PaymentsTable.css";

const PaymentsTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          " https://kiamabackend.mathenoid.com/api/payments"
        );
        setData(response.data.payments);
      } catch (error) {
        console.error("Error fetching payments:", error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handlePrint = () => {
    window.print();
  };

  const groupedData = data.reduce((acc, entry) => {
    const date = formatDate(entry.date);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(entry);
    return acc;
  }, {});

  // Sort the groupedData by date in descending order
  const sortedDates = Object.keys(groupedData).sort((a, b) => {
    const dateA = new Date(a.split("/").reverse().join("-"));
    const dateB = new Date(b.split("/").reverse().join("-"));
    return dateB - dateA;
  });

  return (
    <div className="payments-container">
      <div className="printPayments d-flex justify-content-end p-4">
        <button
          className="btn btn-md btn-success position-fixed"
          onClick={handlePrint}
        >
          print payments
        </button>
      </div>
      <div className="d-flex justify-content-center mb-3  align-items-center text-success fs-3">
        Payments
      </div>
      <div className="payments-content">
        {sortedDates.map((date) => (
          <div key={date} className="payment-group">
            <h3>{date}</h3>
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Member (Payee)</th>
                  <th scope="col">Payment</th>
                  <th scope="col">Amount (Ksh)</th>
                </tr>
              </thead>
              <tbody>
                {groupedData[date].map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.member}</td>
                    <td>{entry.payment}</td>
                    <td>Ksh {entry.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentsTable;
