import React, { useEffect, useState } from "react";
import "./ActiveMembers.css";

const ActiveMembers = () => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await fetch(
          " https://kiamabackend.mathenoid.com/getMembers"
        );
        const data = await response.json();
        if (data.success) {
          setMembers(data.members);
        }
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchMembers();
  }, []);

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th className="greenHeaders" scope="col">
              Serial
            </th>
            <th className="greenHeaders" scope="col">
              Name
            </th>
            <th className="greenHeaders" scope="col">
              Registration
            </th>
            <th className="greenHeaders" scope="col">
              Id
            </th>
            <th className="greenHeaders" scope="col">
              Phone
            </th>
            <th className="greenHeaders" scope="col">
              Email
            </th>
          </tr>
        </thead>
        <tbody>
          {members.map((member, index) => (
            <tr key={member.id_number}>
              <th scope="row">{index + 1}</th>
              <td>{member.name}</td>
              <td>{member.registration_number}</td>
              <td>{member.id_number}</td>
              <td>{member.phone_number}</td>
              <td>{member.email_address}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ActiveMembers;
