import React from "react";
import "./ProfileInitial.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Dp from "../../Assets/Images/person.png";

const PaymentsInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="mainProfileDiv">
        <div className="firstDiv">
          <div className="dp">
            <img
              src={Dp}
              alt="profile"
              width="80px"
              height="80px"
              style={{ borderRadius: "40px" }}
            />
            <label>Nyagah Mbugua</label>
          </div>
          <div className="menus">
            <button className="navBtns"> personal details</button>
            <button className="navBtns"> address details</button>
            <button className="navBtns"> next of kin details</button>
            <button className="navBtns">Repository</button>
          </div>
        </div>
        <div className="verticalLine"></div>
        <div className="secondDiv">
          <form>
            <div class="form-group">
              <label for="exampleInputEmail1">Registration Number</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value="NOID1003"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Id Number</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputPassword1"
                value="3465653622"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Name</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value="Nyagah Mbugua"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Age</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputPassword1"
                value="65"
              />
            </div>
          </form>
        </div>

        <div className="thirdDiv">
          <form>
            <div class="form-group">
              <label for="exampleInputEmail1">Location</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value="Naivasha"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Phone Number</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputPassword1"
                value="077777777"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Religion</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value="christianity"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Gender</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputPassword1"
                value="female"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="lowerUpdateDiv">
        <button className=" btn btn-md btn-success">Update</button>
      </div>
    </Box>
  );
};

export default PaymentsInitial;
