import React from "react";
import SideBarVirtualAssistant from "../../Components/SideBarVirtualAssistant/SideBarVirtualAssistant";

const VirtualAssistant = () => {
  return (
    <div>
      <SideBarVirtualAssistant />
    </div>
  );
};

export default VirtualAssistant;
