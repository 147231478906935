import React, { useState, useEffect } from "react";
import { Button, Table, Modal } from "react-bootstrap";
import axios from "axios";

const AppliedTable = () => {
  const [show, setShow] = useState(false);
  const [action, setAction] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [loans, setLoans] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchLoans();
  }, []);

  const fetchLoans = async () => {
    try {
      const response = await axios.get(
        " https://kiamabackend.mathenoid.com/api/applied-loans"
      );
      console.log("Fetched loans:", response.data.loans); // Add this line for debugging
      setLoans(response.data.loans);
    } catch (error) {
      console.error("Error fetching loans:", error);
      setMessage("Error fetching loans. Please try again.");
    }
  };

  const handleClose = () => {
    setShow(false);
    setMessage("");
  };

  const handleShow = (actionType, row) => {
    setAction(actionType);
    setSelectedRow(row);
    setShow(true);
  };

  const handleConfirm = async () => {
    try {
      if (action === "Approve") {
        await axios.post(
          ` https://kiamabackend.mathenoid.com/api/approve-loan/${selectedRow.id}`
        );
        setMessage("Loan approved successfully.");
      } else if (action === "Reject") {
        await axios.post(
          ` https://kiamabackend.mathenoid.com/api/reject-loan/${selectedRow.id}`
        );
        setMessage("Loan rejected and SMS sent to the applicant.");
      }
      fetchLoans(); // Refresh the loans list
    } catch (error) {
      console.error(`Error ${action.toLowerCase()}ing loan:`, error);
      setMessage(`Error ${action.toLowerCase()}ing loan. Please try again.`);
    }
    handleClose();
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2>Applied Loans</h2>
        <Button variant="primary" onClick={() => window.print()}>
          Print
        </Button>
      </div>
      {message && <div className="alert alert-info">{message}</div>}
      <Table striped hover>
        <Table striped hover>
          <thead className="thead-dark">
            <tr>
              <th>Serial</th>
              <th>Loanee</th>
              <th>Guarantors</th>
              <th>Amount</th>
              <th>Loan Type</th>
              <th>Loan Plan</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loans.map((loan, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{loan.loanee}</td>
                <td>
                  {loan.guarantors.split(" ").map((guarantor, idx) => (
                    <React.Fragment key={idx}>
                      {guarantor}
                      {idx < loan.guarantors.split(" ").length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </td>
                <td>{loan.amount}</td>
                <td>{loan.loantype}</td>
                <td>
                  Years/Month: {loan.loanplan_duration} <br />
                  Interest: {loan.loanplan_interest} <br />
                  Overdue Penalty: {loan.loanplan_penalty}
                </td>
                <td>
                  <Button
                    variant="success"
                    className="mx-2"
                    onClick={() => handleShow("Approve", loan)}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleShow("Reject", loan)}
                  >
                    Reject
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Table>

      <Modal className="mt-5" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm {action}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to {action.toLowerCase()} this loan application?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AppliedTable;
