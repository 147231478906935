import React, { useEffect, useState } from "react";
import "./DashboardAdmin.css";
import UserContext from "../../contexts/UserContext";
import { useContext } from "react";
import axios from "axios";

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const [appliedLoans, setAppliedLoans] = useState([]);
  const [takenLoans, setTakenLoans] = useState([]);
  const [fines, setFines] = useState([]);
  const [latestPayments, setLatestPayments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [appliedLoansRes, takenLoansRes, finesRes, paymentsRes] =
          await Promise.all([
            axios.get(" https://kiamabackend.mathenoid.com/api/applied-loans"),
            axios.get(" https://kiamabackend.mathenoid.com/api/taken-loans"),
            axios.get(" https://kiamabackend.mathenoid.com/api/fines"),
            axios.get(" https://kiamabackend.mathenoid.com/api/payments"),
          ]);

        setAppliedLoans(appliedLoansRes.data.loans);
        setTakenLoans(takenLoansRes.data.loans);
        setFines(finesRes.data.fines);

        // Sort payments by date in descending order and get the latest 4 payments
        const sortedPayments = paymentsRes.data.payments.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        const latestFourPayments = sortedPayments.slice(0, 3);
        setLatestPayments(latestFourPayments);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const totalLoans = takenLoans.reduce(
    (total, loan) => total + parseFloat(loan.amount),
    0
  );
  const totalSavings = 580000; // Hardcoded as there's no API for savings
  const totalInterests = 129000; // Hardcoded as there's no API for interests
  const totalFines = fines.reduce(
    (total, fine) => total + parseFloat(fine.amount),
    0
  );
  const availableAmount = 21345679.5; // Hardcoded as there's no API for available amount

  return (
    <div className="container mt-4">
      <div className="card card-custom mb-4">
        <div className="card-body">
          <h5 className="card-title">Welcome back {user}</h5>
          <p className="card-text">
            We're delighted to have you. Need help on system walk through?
            Navigate to the virtual assistant on the side menu.
          </p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-2">
          <div className="card card-custom text-white bg-primary mb-3">
            <div className="card-body">
              <h5 className="card-title">Total Loans</h5>
              <p className="card-text">KSh {totalLoans.toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="card card-custom text-white bg-success mb-3">
            <div className="card-body">
              <h5 className="card-title">Total Savings</h5>
              <p className="card-text">KSh {totalSavings.toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="card card-custom text-white bg-warning mb-3">
            <div className="card-body">
              <h5 className="card-title">Interests</h5>
              <p className="card-text">KSh {totalInterests.toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="card card-custom text-white bg-danger mb-3">
            <div className="card-body">
              <h5 className="card-title">Total Fines</h5>
              <p className="card-text">KSh {totalFines.toFixed(2)}</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card card-custom text-white bg-info mb-3">
            <div className="card-body">
              <h5 className="card-title">Available Amount</h5>
              <p className="card-text">KSh {availableAmount.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-4">
          <div className="card card-custom">
            <div className="card-header">New Loan Applications</div>
            <div className="card-body">
              <table className="table">
                <thead>
                  <tr>
                    <th>Loanee</th>
                    <th>Amount Applied For</th>
                  </tr>
                </thead>
                <tbody>
                  {appliedLoans.map((loan) => (
                    <tr key={loan.id}>
                      <td>{loan.loanee}</td>
                      <td>KSh {parseFloat(loan.amount).toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-md-6 mb-4">
          <div className="card card-custom">
            <div className="card-header">Latest Payments</div>
            <div className="card-body">
              <table className="table">
                <thead>
                  <tr>
                    <th>Member</th>
                    <th>Item</th>
                  </tr>
                </thead>
                <tbody>
                  {latestPayments.map((payment) => (
                    <tr key={payment.id}>
                      <td>{payment.member}</td>
                      <td>{payment.item}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
